@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

/*------- home page style started -------*/

body{ font-family: 'Poppins', sans-serif;}


a{text-decoration: none;}
p{ color: #575757;font-size: 19px;line-height: 27px;}
h4{ font-size: 35px;font-weight: 500;}
h6{ color: #4267B2;font-size: 20px;font-weight: 400;}
.top-navbar .nav-item .nav-link.active {color: #4267B2; font-weight: 700;}
.top-navbar .nav-item .nav-link{color: #1E1E1E;font-family: Poppins;font-size: 16px;font-style: normal;font-weight: 400;}
.top-navbar .nav-item{margin-right: 30px;}
.dwld-app {  background-color: #4267B2;  display: flex;   padding: 12px 20px;  color: #fff;  width: 180px;  align-items: center;border: none;border-radius: 12px;border-color: #4267B2;position: relative;transition: color 0.3s ease-in-out;overflow: hidden;z-index: 0;}
.dwld-app:hover{ background: #fbcf02;color: #4267b2;}
.dwld-app::before{content: '';position: absolute;background: #fbcf02;height: 100%;width: 0;left: -35%;top: 0;transform: skew(45deg);transition-duration: 0.6s;transform-origin: top left;transition: width,height,0.3s ease-in-out; z-index: -1;}
.dwld-app:hover::before{height: 100%;width: 135%;   }
.dwld-app .dwl-btn p {color: #FFF; font-size: 11px;  font-weight: 400;  margin: 0;line-height: 20px;letter-spacing: normal;}
.dwld-app .dwl-btn h6 {color: #FFF; margin: 0; font-size: 16px; font-weight: 500;}
.dwl-btn{min-height: 40px;text-align: start;}
.nav-btn {  display: flex;}
.banner-text-inner{color: #FFD100;}
.link{cursor: pointer;}
.link-text{color: #0d6efd !important;}
.dwld-app.ms { margin-right: 10px;}
.top-navbar .nav-ui{   display: flex;align-items: center;}
.body-banner{  position: absolute !important;  z-index: -1;  }
.banner h1{ color: #0E42AA;  text-align: center; font-size: 65px; font-weight: 600;}
.banner h2{  color: #0E42AA;  font-size: 43px;  font-style: italic;  font-weight: 500;}
.banner h2 span{   color: #FFD100;}
.banner { text-align: center; margin-top: 100px;}
.banner p{ color: #1E1E1E; text-align: center; font-size: 19px; margin-top: 10px;line-height: 27px;}
.wrap-img img{margin-top: -50px;}
.feature-wrapper{margin-top: 70px;}
.box_parent{ background-image: url('../src/Assets/Images/shape.svg');background-size: contain; background-repeat: no-repeat;padding: 55px;height: 810px;}
.box_parent h3{font-size: 30px; font-weight: 500; margin-top: 15px;}
.box_parent h5{color: #4267B2;margin-top: 15px;}
.feature-text{padding: 0px 18px;}
.feature-wrapper img{width: 230px;}
.friendship-wrapper-text h5{color: #4267B2;}
.friendship-wrapper-text h3{font-size: 35px;font-weight: 500;line-height: 50px;}
.friendship-wrapper-text p{line-height: 27px;width: 100%; text-align: left;}
.conversation-box{width: 100%;background: linear-gradient(180deg, #EBF0FF 0%, rgba(235, 240, 255, 0.00) 100%);height: 100%;clip-path: polygon(0% 100%, 100% 100%, 100% 8%, 0% 0%);margin: 0 auto; padding-bottom: 100px;padding-top: 140px;}
.conversation-box p{line-height: 28px;padding: 0px 375px;}
 footer{background: linear-gradient(226deg, #EBF1FF 0%, rgba(230, 237, 254, 0.81) 100%), #FFF;height: 300px;}
.footer-wrapper{ background-image: url('../src/Assets/Images/footer-bg.png');background-size: contain;background-repeat: no-repeat; padding: 100px 110px; height: 333px;z-index: 9;position: relative;margin-bottom: -182px;}
.footer-logo img{height: 95px;}
.footer-inner-btn a{background: #fff; padding: 7px 10px;border-radius: 7px;}
.footer-wrapper ul{display: flex;padding: 0;margin: 0;}
.footer-wrapper li{list-style: none;color: #fff; margin-top: 15px;display: flex;align-items: center;font-size: 14px;}
.footer-link a{color: #fff;}
.footer-inner-address{justify-content: end;}
.footer-wrapper .material-symbols-outlined {font-variation-settings:'FILL' 1,'wght' 400,'GRAD' 0,'opsz' 48; font-size: 17px;color: #4267b2;background: #fbcf02;border-radius: 50%;height: 30px;width: 30px;display: flex;align-items: center;justify-content: center;}
.footer-bottom{padding-top: 220px;}
.footer-bottom li,.footer-bottom li a{ display: flex;list-style: none; font-size: 14px; color: #4267B2;}
.footer-bottom ul{ display: flex;align-items: center;}
.copy-right{ justify-content: center !important;}
.text-goldie{ color: #fbcf02;}
.footer-inner-btn{display: inline-grid;}
.footer-inner-text{text-align: end;}
.footer-inner-wrapper{display: flex;height: 100%;}
.box_parent h5{font-size: 23px;}
.grecaptcha-badge{z-index: 999;background: #fff;}

/*------ Hover ------*/

.btn-primary{border: none;line-height: 1.6;padding: 12px 24px;border-radius: 12px;border-color: #4267B2;position: relative;color: #0E42AA;transition: color 0.3s ease-in-out;overflow: hidden;background: #0E42AA;color: #fff;z-index: 0;}
.btn-primary:hover{ background: #fbcf02;color: #4267b2;}
.btn-primary::before{content: '';position: absolute;background: #fbcf02;color: #000;height: 100%;width: 0;left: -35%;top: 0;transform: skew(45deg);transition-duration: 0.6s;transform-origin: top left;transition: width,height,0.3s ease-in-out; z-index: -1;}
.btn-primary:hover::before{height: 100%;width: 135%;   }
.watch-btn{border: none; line-height: 1.6; padding: 12px 24px; border-radius: 12px; border-color: #FFD100; position: relative; color: #4267b2; transition: color 0.3s ease-in-out;overflow: hidden; background: #FFD100;z-index: 0;}
.watch-btn:hover{background-color: #0E42AA !important; color: #fff;}
.watch-btn::before{content: ''; position: absolute;background: #0E42AA; color: #fff;height: 100%; width: 0; left: -35%; top: 0; transform: skew(45deg); transition-duration: 0.6s;transform-origin: top left;transition: width,height,0.3s ease-in-out; z-index: -1;}
.watch-btn:hover::before{height: 100%;width: 135%; }

/*------ Hover end ------*/

/*------- home page style ended -------*/

/*------- About page style starts -------*/

.stroy-wapper{margin-top: 50px;}
.stroy-wapper p{text-align: left;}
.wrap-img{  margin-top: 45px;}
.aboutus-wrapper-body{background:  linear-gradient(180deg, #EBF0FF 0%, rgba(235, 240, 255, 0.00) 100%);; height: 100%; clip-path: polygon(0% 100%, 100% 100%, 100% 7%, 0% 0%);padding-bottom: 100px; padding-top: 100px;}
.accordion-button::after {background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");transform: scale(.7) !important; display: block; width: 40px; height: 40px; background-color: #e0e0e0; border-radius: 50%; background-position: 10px; padding: 5px 10px; }
.accordion-button:not(.collapsed)::after {background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");}
.answer-wrapper-body .accordion-item { background-color: #fff; border: none; border-bottom: 1px solid #E7E7E7;}
.answer-wrapper-body span h5{ color: #7F7F7F; font-size: 22px; font-weight: 700; margin-right: 21px;margin-bottom: 0px;line-height: 23px;}
.accordion-questn{  font-size: 20px;line-height: 23px; color: #1E1E1E;font-weight: 500;}
.accordion-button:not(.collapsed) {color: none; background-color: #fff; box-shadow: none; }
.accordion-button:focus {border-color: none;outline: none;box-shadow: none; }
.answer-wrapper-body .card-body p{margin-left: 19x;}
.aboutus-wrapper-body .details-sec img{max-width: 160px;}
.swal2-actions button{background: #0E42AA !important;color:#fbcf02 !important;}
div:where(.swal2-container) div:where(.swal2-popup) {
       width: 29em !important;
}
div:where(.swal2-container) h2:where(.swal2-title) {
       color: black !important;
       font-family: var(--bs-font-sans-serif) !important;
}
.qstn-list{margin: 0;padding: 0;}
.qstn-list li{list-style: none;display: flex;}
.qstn-ans{margin-left: 17px;}



/*------- About page style ends -------*/

/*------- Contact page style starts ------*/

.contact-banner-text h1{text-align: start;}
.contact-banner-text p{text-align: left; font-size: 18px;}
.bnr-cnt-text{text-align: start;}
.contactbanner-inner-wrapper{display: flex;  margin-top: 20px;}
.contactbanner-inner-wrapper h6{  font-size: 26px;}
.contactbanner-inner-wrapper .material-symbols-outlined{font-variation-settings:'FILL' 1,'wght' 400,'GRAD' 0,'opsz' 48; font-size: 40px;color: #4267b2;}
.reachout-warapp-body{  background: linear-gradient(180deg, #EBF0FF 0%, rgba(235, 240, 255, 0.00) 100%);height: 100%;clip-path: polygon(100% 100%,0% 100%,0% 92px,100% 0%);padding-bottom: 70px;padding-top: 100px;margin-top: 30px;}
.contact-submission .form-control{ background: transparent; margin-top: 15px; line-height: 2.2;}
.contact-submission button{background: #4267B2 !important; color: #FFD100;}
.location-inner-body{   position: relative;padding-bottom: 80px;}
.location-overlayer{background-image:url('../src/Assets/Images/location-backgrd.png');background-size: contain;background-repeat: no-repeat; height: 460px;width: 37%;position: absolute;top: 0;padding: 40px 105px 0px 40px;filter: drop-shadow(-1px 3px 3px rgba(50, 50, 0, 0.2));}    
.location-overlayer p{font-size: 15px;}
.location-overlayer h6{font-size: 17px;}
.embed-responsive{ padding-top: 55px;}
.embed-responsive iframe{border-radius: 25px; height: 305px;width: 100%;}
.location-text-sec{ margin-top: 5px; width: 100%;}
.location-overlayer .material-symbols-outlined{font-variation-settings:'FILL' 1,'wght' 900,'GRAD' 0,'opsz' 48; font-size: 36px;color: #4267b2;}
.error{color: #ff0000;font-size: 14px;}

/*------- Contact page style ends --------*/

/*----- privacy policies -------*/
.qstn-num{margin-right: 10px;}
.paragraph-text{margin-left: 50px !important; }
.privacy-wrapper li{list-style: none;}



.wrp-terms p{
       font-size: 18px;
       line-height: 132%;
       color: rgb(93, 108, 121); 
       border: medium;
       margin: 14.95pt 21.2pt 0.0001pt 8.5pt;
}

.main-title {
       font-size: 34px;
       color: #6383c4;
       font-weight: 500;
}

.sub-title{
       font-size: 18px;
       color: #3c64b6;
       margin-top: 17px;
       font-weight: 500;
}

.last-updated{
font-size: 16px;
  color: rgb(74, 74, 74);
}
.inner-titlt{
font-size: 18px;
  color: rgb(60, 100, 182);
  font-weight: 400;
}

.wrp-paragraph{

}

.privacy-ul{
   padding: 0 !important;   
}
.sub-paragraph{
font-size: 17px;
  color: rgb(21, 92, 153);
  font-weight: 500;}













/*--------- Responisve --------*/


@media (max-width: 1399.98px) { 
         p{font-size: 16px;line-height: 27px;}
         h4{ font-size: 25px;}
        .banner h1{font-size: 55px;}
        .box_parent {padding:  45px 0px 10px 0px; height: 740px;}
        .feature-wrapper img {width: 150px;}
        .feature-text {padding: 0px 60px;}
        .conversation-wrapper{ margin-top: 20px;}
        .conversation-box p{padding: 0px 150px;}
        .footer-logo img{height: 90px;}
        .footer-wrapper{ padding: 65px 75px;}
        .footer-wrapper li{font-size: 13px;}
        .footer-bottom li{font-size: 12px;}
        .footer-bottom {padding-top: 180px;}
        footer{height: auto;}
        .conversation-box{ margin-top: 50px;padding-bottom: 45px;padding-top: 80px;}

        /*---- about page ----*/

        h6{font-size: 17px;}
        .qstn-ans { margin-left: 13px; }

        /*---- contact page ----*/
        .banner p{ line-height: 27px;}
        .reachout-warapp-body{ clip-path: polygon(100% 100%,0% 100%,0% 70px,100% 0%);}
        .location-overlayer{ padding: 40px 105px 0px 40px;width: 40%;}
        .embed-responsive iframe {height: 277px;}
 }

 @media (max-width: 1199.98px) { 
        p { font-size: 14px;line-height: 27px;}
        .banner p {font-size: 16px;line-height: 27px;}
        h6{font-size: 16px;}
        .dwld-app{width: 170px;padding: 8px 15px;}
        .banner h1 { font-size: 50px;}
        .banner h2{  font-size: 38px;}
        .wrap-img img {margin-top: -20px;}
        .feature-wrapper img { width: 80px;}
        .box_parent h3{ font-size: 25px;}
        .feature-text { padding: 0px 25px;}
        .box_parent{ height: 610px;}
        .friendship-wrapper-text h3 {font-size: 25px; font-weight: 500; line-height: 35px;}
        .friendship-wrapper-text p { line-height: 28px;width: 100%; }
        .friendship-wrapper-text h5 {color: #4267B2; font-size: 16px;}
        .conversation-box{margin-top: 0px; padding-bottom: 15px;}
        .footer-logo img { height: 72px;  }
        .footer-wrapper {  padding: 60px 55px; }
        .footer-wrapper h4{  font-size: 20px; }
        .footer-wrapper a { padding: 5px 5px; border-radius: 8px;}
        .footer-wrapper li {font-size: 12px;}
        .footer-inner-address li{ margin-top: 3px;}
        .answer-wrapper-body span h5 {line-height: 27px; }

       /*------ contact page -------*/

        .contactbanner-inner-wrapper h6 {font-size: 24px; }
        .location-overlayer {padding: 35px 75px 0px 40px;width: 46%;}
        .embed-responsive iframe {border-radius: 25px; height: 250px;}
        .location-inner-body {padding-bottom: 110px; }
        .reachout-warapp-bod{padding-bottom: 70px;}
}

  @media (max-width: 991.98px) { 
       
       .friendship-wrapper-text p {  text-align: center;}
       .footer-menu a{font-size: 13px;    }
        .banner { margin-top: 55px;}
        .banner h1{font-size: 40px;}
        .banner h2 { font-size: 30px; }
        .banner p {font-size: 15px; line-height: 29px;  }
        .feature-text {padding: 10px 39px;}
        .box_parent {height: auto; background-image: none; background:  linear-gradient(229deg, #EBF1FF 0%, rgba(230, 237, 254, 0.81) 100%); border-radius: 20px;}
        .feature-wrapper img {width: 120px;}
        .friendship-wrapper{ margin-top: 50px;}
        .friendship-wrapper-text{ text-align: center; margin-top: 20px;}
        .footer-wrapper{ background-image: none; background-color: #4267b2; padding: 35px; height: auto; border-radius: 25px; margin-bottom: -155px; box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;}
        .footer-logo{display: flex;justify-content: center; }
        .footer-wrapper ul{ justify-content: center;}
        .footer-inner-btn{ display: flex; justify-content: center; margin-top: 0px; margin-bottom: 10px;}
        .goldie-btn{ margin-left: 30px;}
        .footer-inner-text{ display: flex; justify-content: center;}
        .footer-bottom ul { justify-content: center;}
        .footer-inner-wrapper{ justify-content: center;}
        .footer-wrapper li {font-size: 10px; letter-spacing: 1px;}
        .footer-bottom li { font-size: 14px; letter-spacing: 1px; }
        .conversation-box{clip-path: polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%);padding-top: 50px;}
        .top-navbar .nav-item{margin: 0;}
        
        /*---- about page ----*/

        .aboutus-wrapper-body{clip-path: polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%);}
        h6 {font-size: 20px; }
        p{font-size: 17px;}

         /*---- about page end -----*/

         .contact-banner-text h1,.contact-banner-text p{text-align: center; }
         .contactbanner-inner-wrapper {display: block;}
         .reachout-warapp-body{clip-path: polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%);}
         .location-overlayer{position: unset;background-image: none;text-align: center;width: 100%;height: auto;padding-bottom: 40px;padding: 35px 0px}
         .location-overlayer p{ padding: 0px 105px;}
         .location-mark{justify-content: center;}
         .location-text-sec {width: 100%;}
         .location-inner-body { padding-bottom: 0px; }

        
  }
  
  @media (max-width: 767.98px) { 
         p {font-size: 14px;}
        .banner {margin-top: 35px;}
        .wrap-img{margin-top: 40px;}
        .conversation-box p { padding: 0px 55px; } 
        .banner h2 {font-size: 25px;}
        .footer-wrapper ul {justify-content: center;flex-wrap: wrap;}
        .footer-wrapper{margin-bottom: -200px;}
        .footer-inner-address{ display: block !important;}
        footer{height: 380px; }
        .footer-address-sec{display: flex; justify-content: center;}
        .footer-bottom {padding-top: 250px;}
        .conversation-box {padding-bottom: 30px;}
        .footer-wrapper li {font-size: 13px; letter-spacing: 1px;}

        /*---- about page -----*/

        .aboutus-wrapper-body{padding-top: 50px; }
        .accordion-questn{ font-size: 19px;line-height: 25px;}
        .details-sec{margin-top: 30px; }
        .answer-wrapper-body span h5{font-size: 25px;}
        .qstn-ans{margin-left: 38px;}
        .answer-wrapper-body span h5 {
              line-height: 25px;
              font-size: 20px;
            }
            .dwld-app {
              width: 145px;
              padding: 5px 10px;
            }
            .dwld-app img{width: 25px;}
            .dwld-app .dwl-btn h6 {
              font-size: 15px;
            }
         /*------ about page end ------*/


        .reachout-warapp-body{ padding-top: 50px;padding-bottom: 0px;}
        .contact-submission { margin-top: 40px;}
        .location-overlayer{ padding: 30px 0px;}
        .location-overlayer p{padding: 0px 50px;}
 }
          
@media (max-width: 575.98px) { 
        p{font-size: 14px;}
        h3{ font-size: 20px; }
        h4 {font-size: 20px; }
        h6{font-size: 15px;}
        .banner h1{ font-size: 30px; }
        .nav-btn{flex-wrap: wrap; justify-content: center}
        .dwld-app.ms{margin-right: 10px;margin-bottom: 10px; }
        .feature-text { padding: 0px;}
        .banner h2 { font-size: 20px; }
        .feature-wrapper { margin-top: 50px; }
        .box_parent p{ font-size: 15px; padding: 0px 8px;}
        .conversation-box p {padding: 0px 10px;}
        .footer-wrapper{ padding: 20px; }
        .footer-wrapper li {font-size: 11px;  letter-spacing: .5px; font-weight: 500;}
        .footer-bottom li {font-size: 11px; letter-spacing: .5px;font-weight: 500;}
        .watch-btn{margin-left: 10px;}
        .btn-primary{padding: 12px 20px;}
        .btn-secondary{padding: 12px 20px;}
        .banner p{font-size: 14px;}
        .box_parent p{font-size: 14px;}
        .footer-inner-btn{margin-bottom: 15px;}

         /*------ about page ------*/

        .wrapp-out{ display: block !important; text-align: center; }
        .accordion-questn { font-size: 14px;  }
        .answer-wrapper-body .card-body p{ font-size: 14px;}
        .answer-wrapper-body span h5 {  font-size: 18px;}
        .accordion-questn {line-height: 20px;}
        /* .answer-wrapper-body .card-body p {  margin-left: 53px;} */
        .qstn-ans {margin-left: 28px;}
        .aboutus-wrapper-body .details-sec img { max-width: 110px;}
        .answer-wrapper-body span h5 {line-height: 24px;}
        

        /*------ about page end ------*/

        .contactbanner-inner-wrapper h6 { font-size: 17px;}
        .contactbanner-inner-wrapper .material-symbols-outlined {font-size: 30px;}
        .contactbanner-inner-wrapper p{margin: 0;}
        .contact-banner-bottom {margin-top: 30px;}
        .location-overlayer { padding: 15 10pxpx;}
        .location-overlayer p { padding: 0px;}
        .wrapp-out p{max-width: 410px;margin: auto !important;}
 }

 @media (min-width: 992px) and (max-width: 1199px) {
      
      .footer-wrapper{height: 275px;}
      .footer-wrapper{margin-bottom: -170px; }
     }

